import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import React from 'react'

import useGoogleAuthorize from 'utils/hooks/useGoogleAuthorize'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'

import { baseUrl, guruApiUrl } from 'configs/api'
import {
  APP_LOGIN_STORAGE_KEY,
  GURU_TOKEN_GOOGLE_OAUTH_CONFIG,
} from 'configs/auth'

import type { AuthContextType, UseAuthProvider } from './types'

import type { ApiStatus } from 'types/api'
import type { Session } from 'types/auth'

const AuthContextDefaultValue: AuthContextType = {
  session: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  login: () => {},
  loginScriptIsLoaded: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logout: () => {},
  validateTokenError: null,
  lastUsedEmailDomain: null,
  error: null,
}

export const AuthContext = React.createContext<AuthContextType>(
  AuthContextDefaultValue
)
AuthContext.displayName = 'AuthContext'

export function useWebAuthProvider(immediate = true): UseAuthProvider {
  const {
    run,
    session,
    status,
    error: validateTokenError,
    resetSession,
  } = useValidateToken()

  const [error, setError] = React.useState(null)
  const [lastUsedEmailDomain, setLastUsedEmailDomain] = React.useState(null)

  React.useEffect(() => {
    if (immediate) {
      run(null)
    }
    // eslint-disable-next-line
  }, [])

  const { authorize: login, loaded: loginScriptIsLoaded } = useGoogleAuthorize({
    ...GURU_TOKEN_GOOGLE_OAUTH_CONFIG,
    onSuccess({ idToken, emailDomain }) {
      run(idToken)
      setLastUsedEmailDomain(emailDomain)
    },
    onFailure(loginError) {
      setError(loginError)
    },
  })

  const logout = () => {
    resetSession()
  }

  return {
    session,
    login,
    loginScriptIsLoaded,
    logout,
    status,
    error,
    validateTokenError,
    resetSession,
    lastUsedEmailDomain,
  }
}

export function AuthProvider(props) {
  const {
    session,
    login,
    loginScriptIsLoaded,
    logout,
    status,
    error,
    validateTokenError,
    lastUsedEmailDomain,
  } = useWebAuthProvider()

  const value = React.useMemo(
    () => ({
      session,
      login,
      loginScriptIsLoaded,
      logout,
      error,
      validateTokenError,
      lastUsedEmailDomain,
    }),
    [
      session,
      login,
      loginScriptIsLoaded,
      logout,
      error,
      validateTokenError,
      lastUsedEmailDomain,
    ]
  )

  if (['idle', 'pending'].includes(status)) {
    return null
  } else {
    return <AuthContext.Provider value={value} {...props} />
  }
}

const MAX_REFRESH = 2592000000 // 30 days = 30*24*60*60*1000
function useValidateToken() {
  const [storedSession, setSession, removeSession] = useLocalStorage(
    APP_LOGIN_STORAGE_KEY,
    null
  )
  const [status, setStatus] = React.useState<ApiStatus>('idle')
  const [error, setError] = React.useState(null)

  const run = async (idToken: string | null) => {
    setStatus('pending')

    try {
      let session: Session | null
      if (idToken) {
        session = await validateToken(idToken)
        const isEligible = await isUserEligible(session.user.id)
        if (!!session && isEligible) {
          setSession(session)
          setStatus('success')
        }
      } else if (!!storedSession) {
        const expiredSessionTime = Date.parse(storedSession.expiredAt)
        const currentTime = Date.now()
        const isSessionRefresh =
          !!storedSession &&
          currentTime >= expiredSessionTime &&
          currentTime - expiredSessionTime < MAX_REFRESH
        const isSessionReset =
          !!storedSession && currentTime - expiredSessionTime >= MAX_REFRESH

        if (isSessionReset) {
          removeSession()
          setStatus('idle')
        } else if (isSessionRefresh) {
          session = await refreshToken(storedSession)
          if (!!session) {
            setSession(session)
            setStatus('success')
          }
        }
      }

      if (status !== 'success') {
        throw new Error('User is not allowed to access the page')
      }
    } catch (err) {
      setError(err)
      setStatus('error')
    }
  }

  const resetSession = () => {
    removeSession()
    setStatus('idle')
  }

  return {
    run,
    session: storedSession,
    status,
    error,
    resetSession,
  }
}

async function refreshToken(session: Session): Promise<Session | null> {
  if (!!session) {
    try {
      const axiosConfig: AxiosRequestConfig = {
        url: `${guruApiUrl}/teachers/v1alpha2/guru-token/refresh`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${session.guruToken}`,
        },
      }
      const { data } = (await axios(axiosConfig)) as AxiosResponse<{
        data: Session
      }>
      return data.data
    } catch (error) {
      throw error
    }
  }
}

async function validateToken(idToken: string | null): Promise<Session | null> {
  try {
    const axiosConfig: AxiosRequestConfig = {
      url: `${guruApiUrl}/teachers/v1alpha2/login`,
      method: 'POST',
      data: {
        grantType: 'GOOGLE_SCORECARD',
        token: idToken,
      },
    }
    const { data } = (await axios(axiosConfig)) as AxiosResponse<{
      data: Session
    }>
    return data.data
  } catch (error) {
    throw error
  }
}

const fetchCheckUserExist = async (userId) => {
  const response = await fetch(
    `${baseUrl}/api/guru-service/check-group-exist?userId=${userId}&path=${'/'}`
  )
  const result = await response.json()
  return result.data.exists
}

export const isUserEligible = async (userId: string) => {
  return await fetchCheckUserExist(userId)
}
