export const APP_LOGIN_STORAGE_KEY = 'app-login'

export const ELIGIBLE_GROUP_ID =
  process.env.NEXT_PUBLIC_ENV === 'production' ? 'AJ617JVY97' : 'w8DqbW5x9V' // this is 'scorecard_admin' encoded ID

export const GURU_TOKEN_GOOGLE_OAUTH_CONFIG = {
  clientId: process.env.NEXT_PUBLIC_GOOGLE_AUTH_CLIENT_ID,
  responseType: 'id_token permission',
  cookiePolicy: 'single_host_origin',
  prompt: 'consent',
  scope:
    'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
}
