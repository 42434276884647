import type { NextRouter } from 'next/router'

import type { ProtectedRoute } from 'configs/routes'
import { PROTECTED_ROUTES } from 'configs/routes'

const basePath = process.env.NEXT_PUBLIC_BASE_URL

export const isBrowser = () => typeof global !== 'undefined'

export const isPathProtected = (router: NextRouter): boolean => {
  const url = new URL(`${basePath}${router.pathname}`)

  return PROTECTED_ROUTES.some((route: ProtectedRoute) => {
    if (typeof route === 'string') return router.pathname === route

    const isPathMatched = router.pathname === route.pathname

    if (isPathMatched && !!route.query) {
      // Next router object can return empty query in initial time
      // even though the url should have query,
      // thus we need to get it from global.location.href.
      return !!url.searchParams.get(route.query)
    }

    return isPathMatched
  })
}
