import Head from 'next/head'
import Router from 'next/router'
import NProgress from 'nprogress'
import * as React from 'react'

import { ToastProvider } from '@rapor-pendidikan/toast'
import { reportWebVitals } from '@rapor-pendidikan/toolbox-web-vitals-tracker'
import {
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

import { AuthProvider, ProtectedRoutes } from 'utils/auth'

import '@rapor-pendidikan/design-tokens/dist/guru-desktop/css/tokens.css'
import 'styles/global.css'

Router.events.on('routeChangeStart', () => {
  NProgress.start()
})
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

reportWebVitals()

if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  const mockServer = require('configs/mocks/server').default
  mockServer({ environment: process.env.NODE_ENV })
}

const App = ({ Component, pageProps, router }) => {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: false,
            staleTime: Infinity,
          },
        },
      })
  )

  return (
    <>
      {/* TODO: remove when the mobile view is ready */}
      <Head>
        <meta name="viewport" content="width=1280, initial-scale=1.0" />
      </Head>
      <QueryClientProvider client={queryClient}>
        <HydrationBoundary state={pageProps.dehydratedState}>
          <ToastProvider autoDismiss={true} placement="top-center">
            <AuthProvider>
              <ProtectedRoutes router={router}>
                <Component {...pageProps} key={router.route} />
              </ProtectedRoutes>
            </AuthProvider>
          </ToastProvider>
        </HydrationBoundary>
      </QueryClientProvider>
    </>
  )
}

export default App
