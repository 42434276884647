import React from 'react'

import { useCheckGroupExist } from 'apis/guru-service/useAPIUserManagement'

import { APP_LOGIN_STORAGE_KEY } from 'configs/auth'

import type { ProtectedRoutesComponentProps } from './types'
import * as WebAuth from './useWebAuth'
import { isBrowser, isPathProtected } from './utils'

import type { Session } from 'types/auth'

export function useAuth(immediate = false) {
  /* eslint-enable react-hooks/rules-of-hooks */
  return WebAuth.useWebAuthProvider(immediate)
}

export function AuthProvider(props) {
  return <WebAuth.AuthProvider {...props} />
}

export function ProtectedRoutes({
  router,
  children,
}: ProtectedRoutesComponentProps) {
  const session = getSession()
  const { data: groupCheck, isSuccess: isCheckGroupStatusSuccess } =
    useCheckGroupExist({
      userId: session?.user?.id,
      path: router.pathname,
      enabled: !!session?.user?.id,
    })
  const isEligible = groupCheck?.data.exists
  const shouldLogin = session === null && isPathProtected(router)
  const notPermittedUser = session && isCheckGroupStatusSuccess && !isEligible
  const isRoot = router.pathname === '/'

  if (isBrowser() && shouldLogin) {
    router.replace({
      pathname: '/login',
      query: { from: router.asPath },
    })
    return null
  }

  if (isBrowser() && notPermittedUser && !isRoot) {
    router.replace({
      pathname: '/',
      query: { from: router.asPath },
    })
    return null
  }

  return children
}

export function getSession(
  storageKey: string = APP_LOGIN_STORAGE_KEY
): Session | null {
  try {
    return JSON.parse(window.localStorage.getItem(storageKey))
  } catch {
    return null
  }
}
