import { useQuery } from '@tanstack/react-query'

import { baseUrl } from 'configs/api'

type Params = {
  userId: string
  path: string
  enabled: boolean
}

// Called from client-side
export const useCheckGroupExist = (params: Params) => {
  const { userId, path, enabled } = params
  return useQuery({
    queryKey: ['check-group-exist', userId, path],
    queryFn: async () => {
      const groupExists = await fetch(
        `${baseUrl}/api/guru-service/check-group-exist?userId=${userId}&path=${path}`
      ).then((res) => {
        if (res.ok) {
          return res.json()
        }
        return Promise.reject(res)
      })
      return groupExists
    },
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
    gcTime: 24 * 60 * 60 * 1000, // 24 hours
    enabled,
  })
}
