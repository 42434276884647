import axios, { AxiosResponse } from 'axios'
import React from 'react'

import type { GoogleTokenInfo, GoogleTokens } from 'types/auth'

export interface GoogleAuthorizeResponse {
  readonly accessToken?: string
  readonly idToken?: string
  readonly scope?: string
  readonly expiresIn?: string
  readonly code?: string
  readonly emailDomain?: string
  readonly firstIssuedAt?: string
  readonly expiresAt?: string
}

export interface ResponseSuccessGoogleExchangeToken {
  tokens: GoogleTokens
  tokenInfo: GoogleTokenInfo
}

export interface GoogleAuthorizeProps {
  readonly clientId: string
  readonly scope: string
  readonly cookiePolicy?: string
  readonly loginHint?: string
  readonly hostedDomain?: string
  readonly prompt?: string
  readonly responseType?: string
  readonly includeGrantedScopes?: boolean
}

export interface UseGoogleAuthorizeProps extends GoogleAuthorizeProps {
  readonly onSuccess?: (response: GoogleAuthorizeResponse) => void
  readonly onFailure?: (error: any) => void
  readonly onScriptLoadFailure?: (error: any) => void
  readonly jsSrc?: string
}

export interface UseGoogleAuthorizeResponse {
  authorize: () => void
  loaded: boolean
}

export default function useGoogleIdentityServicesAuthorize({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSuccess = () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onFailure = () => {},
  onScriptLoadFailure,
  clientId,
  scope,
  jsSrc = 'https://accounts.google.com/gsi/client',
}: UseGoogleAuthorizeProps): UseGoogleAuthorizeResponse {
  const scriptId = 'gsi-client'
  const [loaded, setLoaded] = React.useState<boolean>(false)
  const [client, setClient] = React.useState<any>(undefined)

  const initClient = () => {
    // @ts-expect-error
    if (!window.google?.accounts) {
      onFailure('load_script_failed')
      return
    }

    // @ts-expect-error
    const newClient = window.google.accounts.oauth2.initCodeClient({
      client_id: clientId,
      scope,
      ux_mode: 'popup',
      callback: async (response) => {
        if (response.error) {
          onFailure(response.error)
        } else {
          const { code } = response

          try {
            const {
              data: { tokens, tokenInfo },
            } = await axios.post<
              any,
              AxiosResponse<ResponseSuccessGoogleExchangeToken>
            >(`${process.env.NEXT_PUBLIC_BASE_URL}/api/google/exchange-token`, {
              code,
            })

            const authResponse = {
              code,
              accessToken: tokens.access_token,
              idToken: tokens.id_token,
              scope: tokens.scope,
              expiresIn: tokenInfo.exp,
              firstIssuedAt: tokenInfo.iat,
              expiresAt: tokens.expiry_date,
              emailDomain: tokenInfo.email,
            }
            // @ts-expect-error
            onSuccess(authResponse)
          } catch ({ response }) {
            if (response?.data?.data?.error) {
              onFailure(response.data.data.error)
            } else {
              onFailure('login_failed')
            }
          }
        }
      },
    })
    setClient(newClient)
  }

  const initScript = () => {
    const onLoadFailure = onScriptLoadFailure || onFailure
    loadScript(
      scriptId,
      jsSrc,
      () => {
        initClient()
        setLoaded(true)
      },
      (err) => onLoadFailure(err)
    )
  }

  React.useEffect(() => {
    initScript()

    return () => {
      removeScript(scriptId)
    }
    // eslint-disable-next-line
  }, [])

  const authorize = () => {
    if (loaded) {
      client?.requestCode()
    } else {
      initScript()
    }
  }

  return {
    loaded,
    authorize,
  }
}

function loadScript(
  id: string,
  jsSrc: string,
  onLoad: () => void,
  onError: (err: any) => void
) {
  //@ts-expect-error
  const isLoaded = !!window?.google?.accounts
  if (isLoaded) {
    onLoad()
  } else {
    const element = document.getElementsByTagName('script')[0]
    const fjs = element
    let js = element
    js = document.createElement('script')
    js.id = id
    js.src = jsSrc
    if (fjs && fjs.parentNode) {
      fjs.parentNode.insertBefore(js, fjs)
    } else {
      document.head.appendChild(js)
    }
    js.onerror = onError
    js.onload = onLoad
  }
}

function removeScript(id: string) {
  const element = document.getElementById(id)

  if (element) {
    element.parentNode.removeChild(element)
  }
}
