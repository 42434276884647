import { ELIGIBLE_GROUP_ID } from './auth'

export interface ProtectedRouteConfig {
  pathname: string
  query?: string
}

export type ProtectedRoute = ProtectedRouteConfig | string

export const PROTECTED_ROUTES: Array<ProtectedRoute> = [
  '/',
  '/pengaturan-akses/satuan-pendidikan',
  '/pengaturan-akses/dinas-kabupaten-kota',
  '/pengaturan-akses/dinas-provinsi',
]

// routes that can only be visited by user with certain roles
export const ROUTES_PERSMISSION_BY_PATH = {
  '/': ELIGIBLE_GROUP_ID,
  '/pengaturan-akses/satuan-pendidikan': ELIGIBLE_GROUP_ID,
  '/pengaturan-akses/dinas-kabupaten-kota': ELIGIBLE_GROUP_ID,
  '/pengaturan-akses/dinas-provinsi': ELIGIBLE_GROUP_ID,
}
